import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import Vue3FormWizard from 'vue3-form-wizard'
import 'vue3-form-wizard/dist/style.css'
import VueApexCharts from 'vue3-apexcharts'
import loader from 'vue3-ui-preloader'
import 'vue3-ui-preloader/dist/loader.css'

const app = createApp(App)

app.use(Vue3FormWizard)
app.use(VueApexCharts)
app.component('apexChart', VueApexCharts)
app.component('loaderData', loader)

axios.defaults.baseURL = ' https://dev.zata.rw/v1'
// axios.defaults.withCredentials = true
axios.defaults.headers.post['Content-Type'] = 'application/json'

app.use(store).use(router).mount('#app')
