<template>
  <div class="bg-light" style="min-height: 100vh">
    <router-view></router-view>
    <!-- new product  modal -->
    <div
      id="add-product"
      class="modal fade in"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-scrollable modal-lg">
        <div class="modal-content">
          <div class="modal-header d-flex align-items-center">
            <h4 class="modal-title" id="myModalLabel">Record New Product</h4>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="">
              <div class="card-body">
                <div
                  class="alert alert-danger"
                  role="alert"
                  v-if="showErrorMessageProduct"
                >
                  <strong>Error - </strong>
                  {{ errorMessageProduct }}
                </div>
                <div class="row">
                  <div class="col-sm-6">
                    <div class="d-flex align-items-center mb-4">
                      <div class="flex-grow-1 mr-2 me-2">
                        <label
                          for="exampleInputPassword1"
                          class="form-label fw-semibold"
                        >
                          Product Category
                        </label>
                        <select
                          class="form-select"
                          aria-label="Default select example"
                          v-model="selectedCategory"
                        >
                          <option value="" disabled>
                            -- Select Category --
                          </option>
                          <option
                            v-for="category in categories"
                            :key="category.id"
                            :value="category.id"
                          >
                            {{ category.name }}
                          </option>
                        </select>
                      </div>
                      <div class="mb-1">
                        <button
                          type="button"
                          class="btn btn-primary waves-effect waves-light btn-outline-dark"
                          data-bs-toggle="modal"
                          data-bs-target="#samedata-modal"
                          style="margin-top: 30px"
                          title="New Product Category"
                        >
                          <span class="ti ti-plus"></span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="mb-4">
                      <label
                        for="exampleInputPassword1"
                        class="form-label fw-semibold"
                        >Product name</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        v-model="productNameSales"
                        id="productNameSales"
                        placeholder="product name"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-6">
                    <div class="mb-4">
                      <label
                        for="exampleInputPassword1"
                        class="form-label fw-semibold"
                        >Slug</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        v-model="productNameSales"
                        id="productSlugSales"
                        placeholder="slug"
                        readonly
                      />
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="mb-4">
                      <label
                        for="exampleInputPassword1"
                        class="form-label fw-semibold"
                        >Quantity</label
                      >
                      <div class="input-group border rounded-1">
                        <input
                          type="number"
                          v-model="quantity"
                          class="form-control border-0"
                          placeholder="Product Quantity"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-6">
                    <div class="mb-4">
                      <label
                        for="exampleInputPassword1"
                        class="form-label fw-semibold"
                        >Unit</label
                      >
                      <div class="input-group border rounded-1">
                        <select
                          class="form-select"
                          aria-label="Default select example"
                          v-model="selectedUnit"
                        >
                          <option value="" disabled>-- Select Unit --</option>
                          <option
                            v-for="unit in units"
                            :key="unit.id"
                            :value="unit.id"
                          >
                            {{ unit.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="mb-4">
                      <label
                        for="exampleInputPassword1"
                        class="form-label fw-semibold"
                        >Opening Stock</label
                      >
                      <input
                        type="text"
                        v-model="openingStock"
                        class="form-control"
                        placeholder="opening stock"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-6">
                    <div class="mb-4">
                      <label
                        for="exampleInputPassword1"
                        class="form-label fw-semibold"
                        >Purchase Price:</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        v-model="purchasePrice"
                        placeholder="purchase price"
                      />
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="mb-4">
                      <label
                        for="exampleInputPassword1"
                        class="form-label fw-semibold"
                        >Sales Price:</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        v-model="salesPrice"
                        placeholder="Sales price"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-6">
                    <div class="mb-4">
                      <label
                        for="exampleInputPassword1"
                        class="form-label fw-semibold"
                        >Barcode Symbolic</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        v-model="barcode_symbology"
                        placeholder="412 2150 451"
                      />
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="mb-4">
                      <label
                        for="exampleInputPassword1"
                        class="form-label fw-semibold"
                        >Item Code</label
                      >
                      <div class="input-group">
                        <input
                          type="text"
                          class="form-control"
                          v-model="itemCode"
                          placeholder="Enter Item Code"
                        />
                        <a
                          href="#"
                          title="Generate Item barcode"
                          @click="gerateCode()"
                          ><span class="input-group-text"
                            >Generate Code</span
                          ></a
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-6">
                    <div class="mb-4">
                      <label
                        for="exampleInputPassword1"
                        class="form-label fw-semibold"
                        >Opening Stock Date:</label
                      >
                      <input
                        type="date"
                        class="form-control"
                        v-model="openingStockDate"
                        placeholder="opening Stock Date"
                      />
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="mb-4">
                      <label
                        for="exampleInputPassword1"
                        class="form-label fw-semibold"
                        >Tax</label
                      >
                      <select
                        class="form-select"
                        aria-label="Default select example"
                        v-model="selectedTax"
                      >
                        <option value="" disabled>-- Select Tax --</option>
                        <option
                          v-for="tax in taxes"
                          :key="tax.id"
                          :value="tax.id"
                        >
                          {{ tax.name }}
                        </option>
                      </select>
                    </div>
                    <button
                      type="button"
                      class="btn btn-primary"
                      style="margin-left: 230px"
                      @click="createProduct()"
                    >
                      Create Product
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
    <div
      class="modal fade"
      id="samedata-modal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel1"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header d-flex align-items-center">
            <h4 class="modal-title" id="exampleModalLabel1">New Product Category</h4>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <form>
              <div
              class="alert alert-danger"
              role="alert"
              v-if="showErrorMessage"
            >
              <strong>Error - </strong> {{ errorMessage }}
            </div>
              <div class="mb-3">
                <label for="recipient-name" class="control-label"
                  >Category Name:</label
                >
                <input type="text" v-model="productCategoryName" class="form-control" placeholder="category name" />
              </div>
              <div class="mb-3">
                <label for="message-text" class="control-label">Description:</label>
                <textarea class="form-control" placeholder="Description" v-model="descriptionCategory"></textarea>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-light-danger text-danger font-medium"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button type="button" class="btn btn-primary" @click="createCategory();">Create</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import Swal from 'sweetalert2'
export default {
  name: 'App',
  data () {
    return {
      productSlug: '',
      showErrorMessage: false,
      errorMessage: '',
      productName: '',
      productNameSales: '',
      unit: '',
      quantity: '',
      barcode_symbology: '',
      itemCode: '',
      purchasePrice: '',
      salesPrice: '',
      openingStock: '',
      openingStockDate: new Date().toISOString().substr(0, 10),
      selectedCategory: '',
      selectedBranch: '',
      description: '',
      selectedTax: '',
      branches: [],
      categories: [],
      units: [],
      selectedUnit: '',
      taxes: [],
      total_product: '',
      total_product_category: '',
      productCategoryName: '',
      slug: '',
      descriptionCategory: '',
      showErrorMessageCategory: false,
      errorMessageCategory: ''
    }
  },
  methods: {
    gerateCode () {
      this.itemCode = (Math.floor(Math.random() * 9000000000) + 1000000000).toString()
    },
    generateSlug (name) {
      return name.toLowerCase().replace(/\s+/g, '-').replace(/[^a-zA-Z0-9-]/g, '')
    },
    async createCategory () {
      this.showErrorMessage = false
      const token = localStorage.getItem('token')
      const data = {
        name: this.productCategoryName,
        slug: this.productCategoryName
      }
      try {
        const response = await axios.post(
          '/product/category', data, {
            headers:
            {
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
              Authorization: `Bearer ${token}`
            }
          }
        )
        Swal.fire('Product Category Created', response.data.message, 'success')
        this.productCategoryName = ''
        this.descriptionCategory = ''
      } catch (error) {
        this.showErrorMessage = true
        if (error.response.data.message) {
          this.errorMessage = error.response.data.message
        }
        if (error.response.data) {
          this.errorMessage = error.response.data.message
        }
      }
    },
    async createProduct () {
      this.showErrorMessageProduct = false
      const token = localStorage.getItem('token')
      const productsData = [
        {
          name: this.productNameSales,
          barcode_symbology: this.barcode_symbology,
          warehouse_id: '1',
          purchase_price: this.purchasePrice,
          sales_price: this.salesPrice,
          slug: this.productNameSales,
          item_code: this.itemCode,
          category_id: this.selectedCategory,
          unit_id: this.selectedUnit,
          tax_id: this.selectedTax,
          description: this.description,
          stock_quantitiy_alert: this.quantity,
          opening_stock: this.openingStock,
          opening_stock_date: this.openingStockDate
        }
      ]
      const data = {
        products: productsData
      }
      try {
        const response = await axios.post(
          '/product/addnew', data, {
            headers:
            {
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
              Authorization: `Bearer ${token}`
            }
          }
        )
        Swal.fire('Created', response.data.message, 'success')
        // window.location.reload()
        // this.displayProducts()
        window.location.reload()
      } catch (error) {
        this.showErrorMessageProduct = true
        if (error.response.data.message === 'Unauthenticated') {
          window.location.href = '/login'
        }
        if (error.response.data.message) {
          Swal.fire('Error', error.response.data.message, 'error')
        }
      }
    },
    async getDataProductTorecord () {
      this.getProducts()
      const token = localStorage.getItem('token')
      try {
        const response = await axios.get(
          '/company/warehouses', {
            headers:
            {
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
              Authorization: `Bearer ${token}`
            }
          }
        )
        this.branches = response.data
        this.selectedBranch = this.branches[0].id
      } catch (error) {
        console.log(error)
      }
      try {
        const response = await axios.get(
          '/product/category', {
            headers:
            {
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
              Authorization: `Bearer ${token}`
            }
          }
        )
        this.categories = response.data
        this.selectedCategory = this.categories[0].id
      } catch (error) {
        if (error.response.data.message === 'Email is not verified') {
          Swal.fire('Error Happen', error.response.data.message, 'error')
          this.$router.push('/verification-two-steps')
        }
      }
    },
    async productCategory () {
      const token = localStorage.getItem('token')
      // const warehouseCategoryID = localStorage.getItem('warehouseCategoryID')
      try {
        const response = await axios.get(
          '/product/category', {
            headers:
            {
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
              Authorization: `Bearer ${token}`
            }
          }
        )
        this.categories = response.data
      } catch (error) {
        console.log(error)
      }
      // get units from server
      try {
        const response = await axios.get(
          '/common/get-units', {
            headers:
            {
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
              Authorization: `Bearer ${token}`
            }
          }
        )
        this.units = response.data
      } catch (error) {
        console.log(error)
      }
      try {
        const response = await axios.get(
          '/common/get-taxes', {
            headers:
            {
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
              Authorization: `Bearer ${token}`
            }
          }
        )
        this.taxes = response.data
      } catch (error) {
        console.log(error)
      }
    },
    validateTokenHelper () {
      if (localStorage.getItem('token') === null) {
        if (this.$route.path === '/register') {
          this.$router.push('/register')
          return 0
        } else if (this.$route.path === '/forgot-password') {
          this.$router.push('/forgot-password')
          return 0
        }
      }
    }
  },
  mounted () {
    this.validateTokenHelper()
    this.getDataProductTorecord()
    this.productCategory()
  }
}
</script>
