<template>
  <div
    class="alert alert-danger alert-dismissible bg-danger text-white border-0 fade show"
    role="alert"
  >
    <button
      type="button"
      class="btn-close btn-close-white"
      data-bs-dismiss="alert"
      aria-label="Close"
    ></button>
    <i class="ti ti-info-circle fs-5 me-2 text-warning"></i>! Please Record
    Products to Sell into Warehouse First
    <button
      type="button"
      class="btn btn-primary"
      style="margin-left: 550px"
      data-bs-toggle="modal"
      data-bs-target="#add-product"
    >
      New Product
    </button>
  </div>
</template>
