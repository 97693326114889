import axios from 'axios'
export const validateTokenErrorHandling = {

  methods: {
    validateToken () {
      const token = localStorage.getItem('token')
      if (token === null) {
        this.$router.push('/login')
      } else {
        axios.defaults.headers.common.Authorization = 'Bearer ' + token
      }
    }
  }
}
