export const errorHandlingMixins = {

  methods: {
    displayUnauthorized (error) {
      if (error.response.data) {
        alert(error.response.data.message)
      }
      // if (error.response.data.message === 'Unauthorized') {
      //   this.$router.push('/login')
      //   return
      // }
      if (error.response.data.message === 'Unauthenticated') {
        localStorage.removeItem('token')
        this.$router.push('/login')
        return
      }
      if (error.response.data.message) {
        alert(error.response.data.message)
      }
      if (error.response.data.message === 'Email is not verified') {
        this.$router.push('/verification-two-steps')
      }
    }
  }
}
