import { createStore } from 'vuex'

export default createStore({
  state: {
    categoryID: '',
    categoryName: '',
    partyID: '',
    partyName: '',
    partyEmail: '',
    partyPhone: '',
    partyAddress: '',
    expenseID: '',
    expenseCategoryID: '',
    expenseAmount: '',
    expenseDescription: '',
    expenseDate: '',
    productID: '',
    productName: '',
    productQuantity: '',
    partyImage: ''
  },
  getters: {
  },
  mutations: {
    setProductID (state, productID) {
      state.productID = productID
    },
    setProductName (state, productName) {
      state.productName = productName
    },
    setProductQuantity (state, productQuantity) {
      state.productQuantity = productQuantity
    },
    setExpenseID (state, expenseID) {
      state.expenseID = expenseID
    },
    setExpenseCategoryID (state, expenseCategoryID) {
      state.expenseCategoryID = expenseCategoryID
    },
    setExpenseAmount (state, expenseAmount) {
      state.expenseAmount = expenseAmount
    },
    setExpenseDescription (state, expenseDescription) {
      state.expenseDescription = expenseDescription
    },
    setExpenseDate (state, expenseDate) {
      state.expenseDate = expenseDate
    },
    setPartyID (state, partyID) {
      state.partyID = partyID
    },
    setPartyName (state, partyName) {
      state.partyName = partyName
    },
    setPartyEmail (state, partyEmail) {
      state.partyEmail = partyEmail
    },
    setPartyPhone (state, partyPhone) {
      state.partyPhone = partyPhone
    },
    setPartyAddress (state, partyAddress) {
      state.partyAddress = partyAddress
    },
    setPartyImage (state, partyImage) {
      state.partyImage = partyImage
    },
    setCategoryID (state, categoryID) {
      state.categoryID = categoryID
    },
    setCategoryName (state, categoryName) {
      state.categoryName = categoryName
    }
  },
  actions: {
  },
  modules: {
  }
})
