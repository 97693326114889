import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/dashboard',
    name: 'home',
    components: {
      default: HomeView
    },
    children: [
      {
        path: '',
        name: 'DashboardComponent',
        components: {
          dashboard: () => import('../views/DashboardNew.vue')
        }
      },
      {
        path: '/dashboard-new',
        name: 'DashboardNew',
        components: {
          dashboard: () => import('../views/DashboardView.vue')
        }
      },
      {
        path: '/reports',
        name: 'Reports',
        components: {
          dashboard: () => import('../views/ReportView.vue')
        }
      },
      {
        path: '/market',
        name: 'Market',
        components: {
          dashboard: () => import('../views/MarketView.vue')
        }
      },
      {
        path: '/finances',
        name: 'Finances',
        components: {
          dashboard: () => import('../views/FinanceView.vue')
        }
      },
      {
        path: '/website',
        name: 'Website',
        components: {
          dashboard: () => import('../views/WebsiteView.vue')
        }
      },
      {
        path: '/purchases',
        name: 'Purchases',
        components: {
          dashboard: () => import('../views/PurchaseView.vue')
        },
        children: [
          {
            path: '',
            name: 'Purchase',
            components: {
              purchase: () => import('../components/purchase/PurchasedProducts.vue')
            }
          },
          {
            path: '/purchase-new',
            name: 'PurchaseNew',
            components: {
              purchase: () => import('../components/purchase/PurchaseNew.vue')
            }
          },
          {
            path: '/purchase-checkout',
            name: 'PurchaseCheckout',
            components: {
              purchase: () => import('../components/purchase/CheckoutPurchased.vue')
            }
          },
          {
            path: '/purchased-tobe-paid',
            name: 'PurchasedTobePayment',
            components: {
              purchase: () => import('../components/purchase/PurchasedTobepaid.vue')
            }
          },
          {
            path: '/purchased-paid',
            name: 'PurchasedPayment',
            components: {
              purchase: () => import('../components/purchase/PurchasedPaid.vue')
            }
          },
          {
            path: '/purchased-remaining',
            name: 'PurchasedRemaining',
            components: {
              purchase: () => import('../components/purchase/PurchasedRemaining.vue')
            }
          }
        ]
      },
      {
        path: '/payments',
        name: 'Payments',
        components: {
          dashboard: () => import('../views/PaymentsView.vue')
        }
      },
      {
        path: '/profile',
        name: 'ProfileItem',
        components: {
          dashboard: () => import('../components/dashboard/ProfileItem.vue')
        }
      },
      {
        path: '/refer-a-friend',
        name: 'ReferFriend',
        components: {
          dashboard: () => import('../views/ReferFriend.vue')
        }
      },
      {
        path: '/settings',
        name: 'Settings',
        components: {
          dashboard: () => import('../views/SettingsView.vue')
        }
      },
      {
        path: '/stock-history',
        name: 'StockHistory',
        components: {
          dashboard: () => import('../views/StockHistory.vue')
        }
      },
      {
        path: '/customer-parties',
        name: 'CustomerParties',
        components: {
          dashboard: () => import('../views/CustomerParties.vue')
        }
      },
      {
        path: '/supplier-parties',
        name: 'SupplierParties',
        components: {
          dashboard: () => import('../views/SupplierParties.vue')
        }
      },
      {
        path: '/supplier-parties-details',
        name: 'SupplierPartiesDetails',
        components: {
          dashboard: () => import('../views/SupplierPartiesDetails.vue')
        }
      },
      {
        path: '/orders',
        name: 'Orders',
        components: {
          dashboard: () => import('../views/InvoiceView.vue')
        }
      },
      {
        path: '/order-details',
        name: 'OrderDetails',
        components: {
          dashboard: () => import('../views/InvoiceDetails.vue')
        }
      },
      {
        path: '/expenses',
        name: 'Expenses',
        components: {
          dashboard: () => import('../views/ExpensesView.vue')
        }
      },
      {
        path: '/new-expenses',
        name: 'NewExpenses',
        components: {
          dashboard: () => import('../views/NewExpenses.vue')
        }
      },
      {
        path: '/products',
        name: 'ProductItem',
        components: {
          dashboard: () => import('../views/ProductView.vue')
        },
        children: [
          {
            path: '',
            name: 'ProductList',
            components: {
              products: () => import('../components/product/ProductListItem.vue')
            }
          },
          {
            path: '/stock-in',
            name: 'StockIn',
            components: {
              products: () => import('../components/product/ProductStockIn.vue')
            }
          },
          {
            path: '/stock-out',
            name: 'StockOut',
            components: {
              products: () => import('../components/product/ProductStockOut.vue')
            }
          },
          {
            path: '/product-category',
            name: 'ProductCategory',
            components: {
              products: () => import('../components/product/ProductCategoryItem.vue')
            }
          },
          {
            path: '/products/new-category',
            name: 'NewCategory',
            components: {
              products: () => import('../components/product/NewCategoryItem.vue')
            }
          },
          {
            path: 'update-category/:id',
            name: 'update-category',
            components: {
              products: () => import('../components/product/UpdateCategoryItem.vue')
            }
          }
        ]
      }
      // {
      //   path: '/sales',
      //   name: 'Sales',
      //   components: {
      //     dashboard: () => import('../views/SalesView.vue')
      //   }
      // }
    ]
  },
  {
    path: '/maintenance',
    name: 'Maintenance',
    component: () => import('../views/MaintenanceMode.vue')
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/',
    name: 'LandingPage',
    component: () => import('../views/LandingPage.vue')
  },
  {
    path: '/onboarding-company',
    name: 'OnboardingCompany',
    component: () => import('../views/OnboardingCompany.vue')
  },
  {
    path: '/companies',
    name: 'Company',
    component: () => import('../views/CompanyList.vue')
  },
  {
    path: '/proforma',
    name: 'ProForma',
    component: () => import('../views/ProformaView.vue')
  },
  {
    path: '/sales',
    name: 'Sales',
    component: () => import('../views/SalesView.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/authentication/LoginView.vue')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../views/authentication/RegisterView.vue')
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import('../views/authentication/ForgotPassword.vue')
  },
  {
    path: '/forgot-password-message',
    name: 'ForgotPasswordMessage',
    component: () => import('../views/authentication/ForgotPasswordMessage.vue')
  },
  {
    path: '/reset',
    name: 'ResetPassword',
    component: () => import('../views/authentication/ResetingPassword.vue')
  },
  {
    path: '/verification-two-steps',
    name: 'VerificationTwoSteps',
    component: () => import('../views/authentication/VerificationTwoSteps.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
